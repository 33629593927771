import "./index.css";
// import logo-dysto.jpeg
import logo from "./logo-dysto.jpeg";
import bg from "./sama.jpeg";

function App() {
  return (
    <div className="w-screen bg-white">
      <img src={logo} alt="logo" className="w-1/4 mx-auto py-8" />
      {/* Nav Bar with Accueil, Contact and Bienvenu  */}
      <nav className="pb-4">
        <ul className="w-full text-xxs inter-light justify-center inline-flex gap-8">
          <li>
            <a href="#accueil">Accueil</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
          <li>
            <a href="#bienvenu">Bienvenu</a>
          </li>
        </ul>
      </nav>

      <section
        id="accueil"
        className="flex flex-col items-center justify-center gap-3 h-[30vh] bg-gray-100"
      >
        <h1 className="inter-semibold text-xl">UNDER CONSTRUCTION</h1>
        <p className="text-xs inter-light">Coming Soon</p>
      </section>
      {/* Put bg image */}
      <section
        id="bienvenu"
        className="flex flex-col items-center justify-center gap-3"
      >
        <img src={bg} alt="bg" className="w-full" />
      </section>

      <footer
        id="contact"
        className="
      mx-auto w-1/2
      flex flex-col items-center justify-center gap-3 py-10 p-4"
      >
        <h1 className="text-2xl">CONTACT</h1>
        {/* Contact, address, email, and button to email */}
        <p className="text-xs my-1 inter-light">
          25 place la Madeleine, 75008, Paris France
        </p>
        <a
          className="bg-black
            text-center
            text-xs mt-4
           text-white inter-light w-full py-3"
          href="mailto:contact@dysto.co"
        >
          Envoyez nous un email
        </a>
      </footer>

      {/* Map without the review*/}
      <iframe
        title="Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.588890193062!2d2.323181615674239!3d48.86968207928654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e1f06f7a3e7%3A0x7b2b1e1f0d6b2f6e!2sPlace%20de%20la%20Madeleine!5e0!3m2!1sen!2sfr!4v1628581540917!5m2!1sen!2sfr"
        height="450"
        // make it full width
        width="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        // Make it black and white
        className="filter grayscale"
      />
      <p className="text-xxs pt-2 inter-light text-center">
        © 2024 Dysto. All rights reserved.
      </p>
    </div>
  );
}

export default App;
